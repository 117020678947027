const storage = (() => {
  let cache = null;

  return {
    get: () => {
      if (!cache) {
        cache = localStorage.getItem("userObject");
        cache = cache && JSON.parse(cache);
      }
      return cache || {};
    },
    remove: () => {
      localStorage.removeItem("userObject");
      cache = null;
    },
  };
})();

export const getStorage = storage.get;
export const removeStorage = storage.remove;

export const setStorage = (body) => {
  localStorage.setItem("userObject", JSON.stringify(body));
};
