import React, { Suspense } from "react";
import { Switch, Redirect } from "react-router-dom";
import routes from "./AppRoute";
import PrivateRoute from "../util/PrivateRoute";
import PublicRoute from "../util/PublicRoute";

const LandingRoutes = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        {routes.appRoutes.map((route) => {
          if (route.isPrivateRoute) {
            return (
              <PrivateRoute path={route.path} component={route.component} />
            );
          }
          return <PublicRoute path={route.path} component={route.component} />;
        })}

        {/*  If User is authenticated signin redirection will redirect to the configuration list page
       if User is not authenticated it will redirect to the sign in page  */}
        <Redirect to="/signin" />
      </Switch>
    </Suspense>
  );
};

export default LandingRoutes;
