import { lazy } from "react";

const voucherListPage = lazy(() =>
  import("../pages/voucher_page/voucherListPage")
);

const voucherTransactionListPage = lazy(() =>
  import("../pages/voucher_page/voucherTransactionPage")
);

const configurationListPage = lazy(() =>
  import("../pages/configuration/configurationList")
);

const configurationEdit = lazy(() =>
  import("../pages/configuration/configurationEdit")
);

const configurationAdd = lazy(() =>
  import("../pages/configuration/configurationAdd")
);

const signInPage = lazy(() => import("../pages/sign_in/signIn"));

const resyncVoucher = lazy(() =>
  import("../pages/resync_voucher/resyncVoucher")
);

const appRoutes = [
  {
    id: "configurations",
    path: "/configurations",
    component: configurationListPage,
    isPrivateRoute: true,
  },
  {
    id: "vouchers",
    path: "/vouchers",
    component: voucherListPage,
    isPrivateRoute: true,
  },
  {
    id: "vouchertransactions",
    path: "/vouchertransactions",
    component: voucherTransactionListPage,
    isPrivateRoute: true,
  },
  {
    id: "configurationAdd",
    path: "/configurationAdd",
    component: configurationAdd,
    isPrivateRoute: true,
  },
  {
    id: "/configurationEdit",
    path: "/configuration/:id",
    component: configurationEdit,
    isPrivateRoute: true,
  },
  {
    id: "resyncGiftCardData",
    path: "/resyncGiftCardData",
    component: resyncVoucher,
    isPrivateRoute: true,
  },
  {
    id: "signin",
    path: "/signin",
    component: signInPage,
    isPrivateRoute: false,
  },
];

export default {
  appRoutes,
};
